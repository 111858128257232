
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import { Seller } from "@/store/modules/seller.store";

@Component
export default class PageSellerContacts extends Vue {
    @Getter("auth/me") me!: CurrentAccount;
    @Getter("seller/all") sellers!: Seller[];

    get sellersExcludingSelf() {
        return this.sellers.filter((seller: Seller) => Number(seller.id) !== Number(this.me.id)).length;
    }
}
